import React, { useEffect, useState } from 'react'
import type { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import { useIntl } from '@vtex/gatsby-plugin-i18n'
import { Center, Container, Spinner, SuspenseSSR } from '@vtex/store-ui'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useProfile, RenderExtensionLoader } from '@vtex/gatsby-theme-store'
import { useLoginModal } from 'src/contexts/LoginModalContext/useLoginModal'
import Layout from 'src/components/Layout'

import './my-account.css'

const MY_ACCOUNT_PATH = '/account'
const MY_ACCOUNT_DIV_NAME = 'my-account'
const MY_ACCOUNT_EXTENSION_NAME = 'my-account-portal'
const ONE_MIN_IN_MILLI = 60 * 100

const render = async (locale: string) => {
  const loader = new RenderExtensionLoader({
    account: process.env.GATSBY_STORE_ID,
    workspace: process.env.GATSBY_VTEX_IO_WORKSPACE,
    verbose: process.env.NODE_ENV !== 'production',
    publicEndpoint: undefined,
    timeout: ONE_MIN_IN_MILLI,
    path: MY_ACCOUNT_PATH,
    locale,
  })

  const myAccountDiv = document.getElementById(MY_ACCOUNT_DIV_NAME)

  if (window.__RENDER_7_RUNTIME__) {
    loader.render(MY_ACCOUNT_EXTENSION_NAME, myAccountDiv, undefined)

    return
  }

  await loader.load()
  window.__RUNTIME__ = loader.render(
    MY_ACCOUNT_EXTENSION_NAME,
    myAccountDiv,
    undefined
  )
}

const Loading: FC = () => (
  <Center height="750px">
    <Spinner />
  </Center>
)

const MyAccount: FC = () => {
  const profile = useProfile({ stale: false })
  const isAuthenticated = profile?.isAuthenticated?.value === 'true'
  const [loading, setLoading] = useState(true)
  const { locale, defaultLocale } = useIntl()
  const { setIsLoginModalOpen } = useLoginModal()

  useEffect(() => {
    const challengeAndRender = async () => {
      try {
        if (!isAuthenticated) {
          setIsLoginModalOpen(true)

          return
        }

        await render(locale)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    challengeAndRender()
  }, [defaultLocale, isAuthenticated, locale, setIsLoginModalOpen])

  if (!isAuthenticated) {
    return <Loading />
  }

  return (
    <>
      <div id={MY_ACCOUNT_DIV_NAME} />
      {loading && <Loading />}
    </>
  )
}

const Page: FC = () => {
  const { pathname } = useLocation()

  return (
    <>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: `
            function waitForElement(elementRef, callBack){
              window.setTimeout(function(){
                var element = document.querySelector(elementRef);
                if (element) {
                  callBack(elementRef, element);
                } else {
                  waitForElement(elementRef, callBack);
                }
              }, 500)
            }
            try
              {
                waitForElement("a[href='#/orders']",function(){
                  if (!document.querySelector('.vtex-account__menu-links a[href="/wishlist"]')) {
                    var wishlistEl = document.createElement('a');

                    wishlistEl.textContent = 'Wishlist';
                    wishlistEl.href = '/wishlist';

                    wishlistEl.classList.add('vtex-account_menu-link','f6','no-underline','db','hover-near-black','pv5','mv3','pl5','bl','bw2','nowrap','c-muted-1','b--transparent','cursor-pointer');

                    var referenceElement = document.querySelector("a[href='#/orders']");

                    referenceElement.parentNode.insertBefore(
                      wishlistEl,
                      referenceElement.nextSibling
                      );
                    }
                });
              }
            catch (e) { throw new Error(e) }`,
          },
        ]}
        defer
      />
      <GatsbySeo
        title="Minha conta"
        description="Acesse a sua conta e veja seus pedidos e sua lista de desejos"
        titleTemplate="%s | Avon"
        canonical={`https://www.avon.con.br${pathname}`}
      />

      <Layout>
        <Container>
          <SuspenseSSR fallback={<Loading />}>
            <MyAccount />
          </SuspenseSSR>
        </Container>
      </Layout>
    </>
  )
}

export default Page
